.navigation-bar li ul {
    display: none !important;
}
.navigation-bar li:hover ul {
    display: block !important;
}
.navigation-bar {
    width: 100%
}

body {
    width: 85vw;
    margin: auto !important;
}